import { TrackerContext } from 'Components/utils/withTracker'
import { motion } from 'framer-motion'
import { default as React, useContext } from 'react'
import { Link } from 'react-router-dom'
import { actionImg } from 'Components/SessionBar'
import { IframeOptionsContext } from 'Components/utils/IframeOptionsProvider'

export const FFFButton = (props) => {
	const {type, text, colorType, fontSize, fontWeight, ...rest} = props;
	if (type === "Link") {
		return (
			<Link 
				{...rest}
				className={`ui__ button fff ${colorType}`}
				css={`font-size: ${fontSize} !important; font-weight: ${fontWeight} !important` }
			>
				{text}
			</Link>
		)
	}
	else {
		return (
			<button 
				{...rest}
				className={`ui__ button fff ${colorType}`}
				css={`font-size: ${fontSize} !important; font-weight: ${fontWeight} !important` }
			>
				{text}
			</button>
		)
	}
}

export const ActionButton = ({
	text,
	score,
	imgSrc = actionImg,
	invertImage = true,
	url = '/actions',
}) => {
	const tracker = useContext(TrackerContext)

	return (
		<FFFButton 
			type="Link"
			to={url}
			colorType='fff_blue'
			onClick={() =>
				tracker.push([
					'trackEvent',
					'NGC',
					'Clic bouton action page /fin',
					null,
					score,
				])
			}
			text={text}
		/>
	)
}

export const IntegratorActionButton = () => {
	const { integratorLogo, integratorActionUrl, integratorActionText } =
		useContext(IframeOptionsContext)

	return (
		<a
			href={integratorActionUrl}
			className="ui__ button plain"
			target="_blank"
			css={`
				margin: 0.6rem auto 1rem;
				width: 90%;
				img {
					transform: scaleX(-1);
					height: 2rem;
					margin: 0 0.6rem;
					display: inline-block;
				}
				a {
					color: var(--textColor);
					text-decoration: none;
				}
			`}
		>
			<div
				css={`
					display: flex;
					justify-content: center;
					align-items: center;
					@media (max-width: 800px) {
						flex-direction: column-reverse;
						img {
							display: none;
						}
					}
				`}
			>
				{integratorActionText}
				<img src={integratorLogo} />
			</div>
		</a>
	)
}
