export default () => (
	<span
		css={`
			margin-left: 0.6rem;
			background: var(--color);
			color: var(--textColor);
			padding: 0.1rem 0.4rem;
			border-radius: 0.6rem;
		`}
	>
		beta
	</span>
)
